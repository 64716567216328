<template>
  <div class="dog-handler">
    <p>
      {{ handler.handlerName }}
    </p>
    <font-awesome-icon
      :icon="['fa', 'minus-circle']"
      @click="removeHandler(handler)"
    ></font-awesome-icon>
  </div>
</template>

<script>
export default {
  props: { handler: Object, removeHandler: Function },
};
</script>

<style lang="scss" scoped>
.dog-handler {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;

  svg:hover {
    cursor: pointer;
    color: $secondary;
  }
}

p {
  margin: 0 5px 5px 0;
  padding: 10px 20px;
  width: 250px;
  font-weight: bold;
  background: #fff;
  border: 1px solid $tertiary-25;
  border-radius: 5px;
}
</style>
