<template>
  <div class="dog-top-actions">
    <el-tooltip effect="dark" content="Add Handler" placement="left">
      <font-awesome-icon
        :icon="['fa', 'plus-circle']"
        @Click="openDialogue"
      ></font-awesome-icon>
    </el-tooltip>
  </div>
  <div v-if="getHandlers.length > 0">
    <DogHandler
      v-for="handler in getHandlers"
      :key="handler.itemId"
      :handler="handler"
      :removeHandler="removeHandler"
    />
  </div>
  <div v-else>
    <h3>No handlers added at this time.</h3>
  </div>

  <el-dialog
    title="Add Handler"
    :modelValue="dialogueVisible"
    width="40%"
    :before-close="closeDialogue"
  >
    <base-spinner v-if="isUpdating"></base-spinner>
    <el-input
      placeholder="Handler Name"
      v-else-if="!isUpdating"
      v-model="newHandler"
    ></el-input>
    <h3 v-else>Something went wrong</h3>
    <span class="buttons">
      <base-button color="secondary" @click="addHandler"
        >Add Handler</base-button
      >
      <base-button color="tertiary" @click="closeDialogue">Cancel</base-button>
    </span>
  </el-dialog>
</template>

<script>
import DogHandler from "../components/dog/DogHandler.vue";
import { uuid } from "vue-uuid";

export default {
  components: { DogHandler },
  data() {
    return {
      isUpdating: false,
      dialogueVisible: false,
      newHandler: undefined,
    };
  },
  computed: {
    getDogId() {
      return this.$store.getters["dog/getDog"]._id;
    },
    getHandlers() {
      return this.$store.getters["dog/getDog"].handlers;
    },
  },
  methods: {
    openDialogue() {
      this.dialogueVisible = true;
    },
    closeDialogue() {
      this.dialogueVisible = false;
    },
    async addHandler() {
      this.isUpdating = true;
      try {
        let updatedDogHandlers = [...this.getHandlers];

        updatedDogHandlers.push({
          itemId: uuid.v4(),
          handlerName: this.newHandler,
        });

        await this.$store.dispatch("dog/updateDog", {
          dogId: this.getDogId,
          update: { handlers: updatedDogHandlers },
        });

        this.$message({
          showClose: true,
          message: `${this.newHandler} added`,
          type: "success",
          duration: 5000,
        });
        this.newHandler = undefined;
      } catch (error) {
        console.log(error);
        this.$message({
          showClose: true,
          message: "Error DHV1000 adding handler",
          type: "error",
          duration: 5000,
        });
      }

      this.isUpdating = false;
      this.closeDialogue();
    },
    removeHandler(handlerToRemove) {
      const dogHandlers = [...this.getHandlers];

      this.$confirm(
        `Are you sure you want to remove ${handlerToRemove.handlerName}`,
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            const updatedDogHandlers = dogHandlers.filter(
              (handler) => handler.itemId !== handlerToRemove.itemId
            );

            await this.$store.dispatch("dog/updateDog", {
              dogId: this.getDogId,
              update: { handlers: updatedDogHandlers },
            });

            this.$message({
              showClose: true,
              message: `${handlerToRemove.handlerName} removed`,
              type: "success",
              duration: 5000,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error DHV1100 removing handler",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch(() =>
          this.$message({
            type: "info",
            message: "Remove Cancelled",
          })
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  button {
    margin: 10px 10px 0 0;
  }
}

.dog-top-actions {
  margin-bottom: 15px;
}
</style>
